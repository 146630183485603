import {
  mobile,
  backend,
  creator,
  web,
  javascript,
  html,
  css,
  reactjs,
  redux,
  nodejs,
  wordpress,
  expressjs,
  mongodb,
  landing,
  floorfy,
  git,
  softThrive,
  mui,
  bootstrap,
  fiverr,
  florería,
  rehub,
  dbf,
  ticket,
  dev,
  siteforge,
  crypto,
  ecommerce,
  dynamicdashboard,
  phonediscover,
  nextjs,
  aeyron,
} from "../assets";

export const navLinks = [
  {
    id: "about",
    title: "About",
  },
  {
    id: "work",
    title: "Work",
  },
  {
    id: "contact",
    title: "Contact",
  },
  {
    id: "github",
    url: "https://github.com/MuhammadSanaullah123",
    title: "GitHub",
  },
  {
    id: "linkedin",
    url: "https://www.linkedin.com/in/muhammad-sanaullah-322957218/",
    title: "Linkedin",
  },
  {
    id: "resume",
    url: "https://res.cloudinary.com/dmtcur7kt/image/upload/v1716565234/Muhammad_Resume_q4q1no.pdf",
    title: "Resume",
  },
];

const services = [
  {
    title: "Computer Engineer",
    icon: creator,
  },
  {
    title: "Full Stack Web Developer",
    icon: web,
  },
  {
    title: "WordPress Developer",
    icon: mobile,
  },
  {
    title: "Software Developer",
    icon: backend,
  },
];

const technologies = [
  {
    name: "HTML5",
    icon: html,
  },
  {
    name: "CSS3",
    icon: css,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "Next.js",
    icon: nextjs,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },
  {
    name: "Express JS",
    icon: expressjs,
  },
  {
    name: "MongoDB",
    icon: mongodb,
  },
  {
    name: "Redux Toolkit",
    icon: redux,
  },
  {
    name: "wordpress",
    icon: wordpress,
  },
  {
    name: "mui",
    icon: mui,
  },
  {
    name: "bootstrap",
    icon: bootstrap,
  },
];

const experiences = [
  {
    title: "Full Stack Developer",
    company_name: "Aeyron",
    icon: aeyron,
    iconBg: "#E6DEDD",
    date: "Sep 2024 - Present",
    points: [
      "Developed and managed web applications utilizing HTML, SASS, React.js, Node.js, Express.js",
      "Integrated with databases like MongoDB and PostgreSQL using ORMs such as Sequelize",
      "Developed dynamic web applications using Next.js for server-side rendering and static site generation",
      "Designed intuitive user interfaces adhering to Google Material Design guidelines using Material-UI",
      "Communicated effectively with stakeholders to gather detailed requirements",
      "Managed the end-to-end process of design, development, documentation, and integration efforts",
      "Utilized Nodemailer, SendGrid, EmailJS for sending emails, and Twilio SMS for sending text messages to users",
      "Ensured reliable communication using email and SMS services",
      "Leveraged Google API for incorporating Google Maps and Google Cloud Storage functionalities",
      "Followed best practices in code organization, performance optimization",
      "Ensured seamless collaboration with cross-functional teams",
      "Developed Maritime Vessel Management System and Telehealth Appointment-Examination & Report Analysis System",
    ],
  },
  {
    title: "Full Stack Developer",
    company_name: "SoftThrive",
    icon: softThrive,
    iconBg: "#E6DEDD",
    date: "Nov 2023 - Aug 2024",
    points: [
      "Built and maintained web applications using HTML, SASS, React.js, Node.js, Express.js, integrated with MongoDB",
      "Implemented Redux and Redux Toolkit for efficient state management across the website",
      "Incorporated Payment Gateways (Stripe and Square Invoicing) for seamless transaction processing",
      "Utilized Nodemailer, SendGrid, EmailJS for sending emails, and Twilio SMS for sending text messages to users, ensuring reliable communication",
      "Leveraged Google API for incorporating Google Maps and Google Cloud Storage functionalities",
      "Enhanced website interactive features by embedding Agora.io Web SDK for video calls, resulting in a 40% increase in user satisfaction and a 25% rise in repeat visits",
      "Deployed web applications on Heroku, AWS EC2, and Netlify, ensuring robust and scalable hosting solutions",
      "Developed Physiotherapy, E-commerce, Bus Ticket Booking, Social Platform, Virtual Property Tour Platform",
    ],
  },
  {
    title: "Frontend Intern",
    company_name: "SoftThrive",
    icon: softThrive,
    iconBg: "#E6DEDD",
    date: "Aug 2021 - Oct 2021",
    points: [
      "Developed interfaces with React.js, SCSS, Material-UI, Ant Design, for a user-friendly experience.",
      "Enforced responsive design and cross-browser compatibility for optimal performance across different devices.",
      "Collaborated with cross-functional teams, utilizing GitHub for version control and code management, and conducted code reviews to maintain high code quality",
      "Optimized performance by implementing lazy loading and code splitting techniques, resulting in a 25% reduction in load times.",
      "Integrated RESTful APIs and implemented state management solution using Redux for global state management.",
      "Troubleshot and resolved front-end issues and bugs, improving application stability and performance.",
    ],
  },
];

const testimonials = [
  {
    testimonial:
      "An incredibly hardworking person, consistently delivered exceptional results by creating top-notch web applications.",
    name: "Abdullah Shahid",
    designation: "CTO",
    company: "SoftThrive",
    image:
      "https://res.cloudinary.com/dmtcur7kt/image/upload/v1685210230/Abdullah_trqthr.jpg",
  },
  {
    testimonial:
      "He communicated excellently and completed the complex project swiftly. He worked fast and delivered well before the scheduled time.",
    name: "Hogan",
    designation: "Client",
    company: "Fiverr",
    image:
      "https://res.cloudinary.com/dmtcur7kt/image/upload/v1685220351/Hogan_h12eot.jpg",
  },
  {
    testimonial:
      "He greatly improved our online presence through his optimization of our website. We are incredibly grateful for his efforts!",
    name: "Sophie	Black",
    designation: "Lead Developer",
    company: "Star Enterprises",
    image: "https://randomuser.me/api/portraits/women/6.jpg",
  },
];

const projects = [
  {
    name: "SiteForge",
    description:
      "A web application where users choose from a given template of pages and components and complete react application with routing and styling is created on user github and deployed live on Netlify",
    tags: [
      {
        name: "reactjs",
        color: "blue-text-gradient",
      },
      {
        name: "nodejs",
        color: "green-text-gradient",
      },
      {
        name: "github api",
        color: "black-text-gradient",
      },
      {
        name: "netlify api",
        color: "blue-text-gradient",
      },
      {
        name: "scss",
        color: "pink-text-gradient",
      },
    ],
    image: siteforge,
    source_code_link: "https://github.com/MuhammadSanaullah123/SiteForge",
    live_code_link: "https://siteforge-dcd443189d34.herokuapp.com",
  },
  {
    name: "DevConnector",
    description:
      "A developer-focused application where you can set up your profile, share code snippets or projects, interact with other developers through comments and likes.",
    tags: [
      {
        name: "reactjs",
        color: "blue-text-gradient",
      },
      {
        name: "nodejs",
        color: "green-text-gradient",
      },
      {
        name: "scss",
        color: "pink-text-gradient",
      },
    ],
    image: dev,
    source_code_link: "https://github.com/MuhammadSanaullah123/DevConnector",
    live_code_link: "https://peaceful-journey-45205.herokuapp.com",
  },
  {
    name: "Cam",
    description:
      "A real-estate application enabling the creation of virtual tours and 3D floorplans, with integrated real-time video call functionality for client demonstrations.",
    tags: [
      {
        name: "reactjs",
        color: "blue-text-gradient",
      },
      {
        name: "nodejs",
        color: "green-text-gradient",
      },
      {
        name: "virtual_tour",
        color: "pink-text-gradient",
      },
    ],
    image: floorfy,
    source_code_link: "https://github.com/MuhammadSanaullah123/Floorfy-Cam",
    live_code_link: "https://vr.camc.sa",
  },
  {
    name: "Unitify E-Commerce",
    description:
      "E-Commerce platform that enables users to purchase products across various categories and provides the convenience of online payment options, including traditional card payments as well as the newly introduced cryptocurrency.",
    tags: [
      {
        name: "reactjs",
        color: "blue-text-gradient",
      },
      {
        name: "nodejs",
        color: "green-text-gradient",
      },
      {
        name: "scss",
        color: "pink-text-gradient",
      },
    ],
    image: ecommerce,
    source_code_link:
      "https://github.com/MuhammadSanaullah123/Unitify_ECommerce_AWS",
    live_code_link: "http://3.108.247.218/home",
  },
  {
    name: "Unitify Crypto",
    description:
      "A Crypto platform that offers users the ability to view predicted value graphs of cryptocurrencies generated by ML models, as well as the option to post their projects for funding by investors using cryptocurrency.",
    tags: [
      {
        name: "reactjs",
        color: "blue-text-gradient",
      },
      {
        name: "mui",
        color: "green-text-gradient",
      },
      {
        name: "scss",
        color: "pink-text-gradient",
      },
    ],
    image: crypto,
    source_code_link: "https://github.com/MuhammadSanaullah123/Unitify-Crypto",
    live_code_link: "https://unitifycrypto.netlify.app",
  },
  {
    name: "Design E-Commerce Platform",
    description:
      "Social media based e-commerce platform which allows users to post designs for purchase and are able to create story, highlights and follow other user's profile.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "nodejs",
        color: "green-text-gradient",
      },
      {
        name: "css",
        color: "pink-text-gradient",
      },
    ],
    image: dbf,
    source_code_link:
      "https://github.com/MuhammadSanaullah123/Design-E-Commerce-Platform",
  },
  {
    name: "Travel Agency System",
    description:
      "Web based travel system allowing users to book and buy bus tickets online. Users can also avail cargo, umrah, hajj tourism and bus rental services.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "nodejs",
        color: "green-text-gradient",
      },
      {
        name: "css",
        color: "pink-text-gradient",
      },
    ],
    image: ticket,
    source_code_link: "https://github.com/MuhammadSanaullah123/TicketingSystem",
  },
  {
    name: "Rehub",
    description:
      "Web-based platform that allows us to book a space for cuztomized date and time in any location in Cyprus for physiotherapy.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "nodejs",
        color: "green-text-gradient",
      },
      {
        name: "scss",
        color: "pink-text-gradient",
      },
    ],
    image: rehub,
    source_code_link: "https://github.com/MuhammadSanaullah123/Rehub",
    live_code_link: "  https://rehubcy.com/home",
  },
  {
    name: "Florería Suecia",
    description:
      "An online marketplace where you can buy floral arranglement and gifts",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "mongodb",
        color: "green-text-gradient",
      },
      {
        name: "scss",
        color: "pink-text-gradient",
      },
    ],
    image: florería,
    source_code_link: "https://github.com/MuhammadSanaullah123/Floreria",
    live_code_link: "https://www.floreriasuecia.cl",
  },
  {
    name: "Dynamic Dashboard Builder",
    description:
      "Dynamic dashboard builder built on React and react-chartjs-2 which takes name of chart and data from user and displays any widget dynamically",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "react-chartjs-2",
        color: "green-text-gradient",
      },
      {
        name: "vite",
        color: "pink-text-gradient",
      },
    ],
    image: dynamicdashboard,
    source_code_link:
      "https://github.com/MuhammadSanaullah123/DynamicDashboardBuilder",
    live_code_link: "https://dynamicdashboardbuilder.netlify.app",
  },
];

export { services, technologies, experiences, testimonials, projects };
